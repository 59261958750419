import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Button, Paper } from '@mui/material';

const TradingPairsTable = ({ tradingPairs, setEditTradingPair, setEditPair, handleDeleteTradingPair }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(100); // Initial page size

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Paper>
        <TableContainer>
            <Table size="small">
            <TableHead>
                <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Trading Pair</TableCell>
                <TableCell>Pair Decimals</TableCell>
                <TableCell>Asset Decimals</TableCell>
                <TableCell>Quote Asset Decimals</TableCell>
                <TableCell>Edit</TableCell>
                <TableCell>Delete</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {tradingPairs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow key={row.id}>
                    <TableCell>{row.id}</TableCell>
                    <TableCell>{row.trading_pair}</TableCell>
                    <TableCell>{row.pair_decimals}</TableCell>
                    <TableCell>{row.asset_decimals}</TableCell>
                    <TableCell>{row.quote_asset_decimals}</TableCell>
                    <TableCell>
                    <Button onClick={() => { setEditTradingPair(row); setEditPair(row); }} variant="outlined" color="primary">
                        Edit
                    </Button>
                    </TableCell>
                    <TableCell>
                    <Button onClick={() => handleDeleteTradingPair(row.id)} variant="outlined" color="error">
                        Delete
                    </Button>
                    </TableCell>
                </TableRow>
                ))}
            </TableBody>
            </Table>
        </TableContainer>
        <TablePagination
            component="div"
            count={tradingPairs.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[100, 200, 300]}
        />
        </Paper>
    );
};

export default TradingPairsTable;
